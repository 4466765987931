import {useTranslation} from 'react-i18next'
import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  const {t} = useTranslation(['navigation'])

  return (
    <>
      <SidebarMenuItem to='/home' icon='home' title={t('sidebar.home')} />

      <SidebarMenuItem to='/device/list' icon='data' title={t('sidebar.device_list')} />

      <SidebarMenuItem to='/history' icon='archive' title={t('sidebar.history')} />

      <SidebarMenuItem to='/stats' icon='chart-simple' title={t('sidebar.stats')} />

      <SidebarMenuItem to='/users' icon='people' title={t('sidebar.users')} />
    </>
  )
}

export {SidebarMenuMain}
