import {FC} from 'react'
import {Link} from 'react-router-dom'
import {KTIcon} from '../../../../_metronic/helpers'
import {useTranslation} from 'react-i18next'

const LastMessages: FC = () => {
  const {t} = useTranslation(['home'], {keyPrefix: 'last'})

  return (
    <div className='card mb-5'>
      <div className='card-header border-0'>
        <h3 className='card-title'>{t('title')}</h3>
        <div className='card-toolbar gap-3'>
          <Link to='/history'>
            <KTIcon iconName='arrow-up-right' className='fs-1' />
          </Link>
        </div>
      </div>

      <div className='px-8 pb-8 collapse show'>
        <div className='card position-relative'>
          <div className='timeline-label'>
            <div className='timeline-item'>
              <div className='timeline-label text-gray-800 fs-6'>08:42</div>
              <div className='timeline-badge'>
                <KTIcon iconName='message-text' className='fs-1' />
              </div>
              <div className='timeline-content d-flex'>
                <span className='fw-bold text-gray-800 ps-3'>Dispositivo 1</span>
                <span className='fw-mormal text-muted ps-3'>TI 1 - Counter: 2</span>
              </div>
            </div>

            <div className='timeline-item'>
              <div className='timeline-label text-gray-800 fs-6'>10:00</div>
              <div className='timeline-badge'>
                <KTIcon iconName='message-text' className='fs-1' />
              </div>
              <div className='timeline-content d-flex'>
                <span className='fw-bold text-gray-800 ps-3'>Dispositivo 2</span>
                <span className='fw-mormal text-muted ps-3'>Input 1 - Ligado</span>
              </div>
            </div>

            <div className='timeline-item'>
              <div className='timeline-label text-gray-800 fs-6'>14:37</div>
              <div className='timeline-badge'>
                <KTIcon iconName='message-edit' className='fs-1' />
              </div>
              <div className='timeline-content d-flex'>
                <span className='fw-bold text-gray-800 ps-3'>Dispositivo 2</span>
                <span className='fw-mormal text-muted ps-3'>Nova configuração</span>
              </div>
            </div>

            <div className='timeline-item'>
              <div className='timeline-label text-gray-800 fs-6'>16:50</div>
              <div className='timeline-badge'>
                <KTIcon iconName='message-text' className='fs-1' />
              </div>
              <div className='timeline-content d-flex'>
                <span className='fw-bold text-gray-800 ps-3'>Dispositivo 1</span>
                <span className='fw-mormal text-muted ps-3'>TI 1 - Counter: 2</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LastMessages
