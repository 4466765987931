import {createContext, useContext, useEffect, useState} from 'react'
import axios from 'axios'
import {DeviceInterface} from '../../pages/device/components/DeviceInterface'

interface DeviceContextType {
  devices: DeviceInterface[]
  addDevice: (insertedDevice: DeviceInterface) => Promise<number>
  updateDevice: (updatedDevice: DeviceInterface) => Promise<void>
  removeDevice: (id: number) => Promise<void>
}

const DeviceContext = createContext<DeviceContextType | undefined>(undefined)

export const useDeviceContext = () => {
  const context = useContext(DeviceContext)
  if (!context) {
    throw new Error('useDeviceContext must be used within a DeviceProvider')
  }
  return context
}

export function DeviceContextProvider({children}) {
  const [devices, setDevices] = useState<DeviceInterface[]>([])

  const fetchDevices = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/device/list`)
      .then((response) => {
        setDevices(response.data)
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const removeDevice = async (id: number) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/device/${id}`)
      .then((response) => {
        console.log(response)

        setDevices((prevDevices) => prevDevices.filter((m) => m.id !== id))
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const updateDevice = async (updatedDevice: DeviceInterface) => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/device/${updatedDevice.id}`, updatedDevice)
      .then((response) => {
        console.log(response)

        setDevices((prevDevices) =>
          prevDevices.map((m) => (m.id === updatedDevice.id ? updatedDevice : m))
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const addDevice = async (insertedDevice: DeviceInterface) => {
    return axios
      .put(`${process.env.REACT_APP_API_URL}/api/device/`, insertedDevice)
      .then((response) => {
        console.log(response)

        const {id_device} = response.data
        setDevices((prevDevices) => {
          insertedDevice.id = id_device
          return [...prevDevices, insertedDevice]
        })

        return Promise.resolve(id_device)
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  useEffect(() => {
    fetchDevices()
  }, [])

  return (
    <DeviceContext.Provider value={{devices, addDevice, updateDevice, removeDevice}}>
      {children}
    </DeviceContext.Provider>
  )
}
