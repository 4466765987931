interface Props {
  children: string
  value: boolean
  onChange: (value: boolean) => void
}

const SwitchButton = ({children, value, onChange}: Props) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked)
  }

  return (
    <div className='form-check form-switch form-check-custom form-check-solid'>
      <input
        className='form-check-input'
        type='checkbox'
        aria-label={children}
        checked={value}
        onChange={handleInputChange}
        id='switchButton'
      />
      <label className='form-check-label text-dark' htmlFor='switchButton'>
        {children}
      </label>
    </div>
  )
}

export default SwitchButton
