import {FC, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import ApexCharts from 'apexcharts'
import {useThemeMode} from '../../../../_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {getCSS} from '../../../../_metronic/assets/ts/_utils'
import {useDatasetContext} from '../../../modules/dataset'
import {buttons, getChartOptions} from './ChartUtils'

const Chart: FC = () => {
  const {t, i18n} = useTranslation(['home'], {keyPrefix: 'chart'})
  const {mode} = useThemeMode()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [buttonActive, setButtonActive] = useState<0 | 1 | 2>(0)
  const {dataset} = useDatasetContext()

  function getMonthName(monthNumber) {
    const date = new Date()
    date.setMonth(monthNumber - 1)

    const lang = i18n.language
    const result = date.toLocaleString(lang === 'en' ? 'en-US' : 'pt-PT', {month: 'long'})

    return result.charAt(0).toUpperCase() + result.slice(1)
  }

  const generateChartData = () => {
    let list: {month: number; count: number}[] = []

    for (let i = 0; i < dataset.length; i++) {
      const month = dataset[i].timestamp.getMonth()

      const idx = list.findIndex((item) => item.month === month)
      if (idx === -1) list.push({month, count: 1})
      else list[idx].count++
    }

    return list
  }

  const refreshMode = () => {
    if (!chartRef.current || !dataset.length) return

    const list = generateChartData()
    const data = list.map((item) => item.count)
    const months = list.map((item) => getMonthName(item.month))

    const height = parseInt(getCSS(chartRef.current, 'height'))
    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(data, months, height, t('messages'))
    )
    if (chart) chart.render()

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()
    return () => {
      if (chart) chart.destroy()
    }
  }, [chartRef, mode, dataset, i18n.language])

  return (
    <div className='card mb-5'>
      <div className='card-header border-0'>
        <h3 className='card-title'>{t('title')}</h3>

        <div className='card-toolbar'>
          {buttons.map((button) => {
            return (
              <button
                key={button.index}
                onClick={() => setButtonActive(button.index)}
                className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${
                  buttonActive === button.index && 'active'
                }`}
              >
                {t(button.label)}
              </button>
            )
          })}
        </div>
      </div>

      <div className='card-body pt-0'>
        <div ref={chartRef} id='chart' style={{height: '250px'}}></div>
      </div>
    </div>
  )
}

export default Chart
