import {FC, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import {toAbsoluteUrl} from '../../../_metronic/helpers/AssetHelpers'
import {formatDate} from '../../helpers'
import {useAuth} from '../../modules/auth'
import {useUserContext} from '../../modules/users'
import InputSearch from '../../GenericComponents/InputSearch'
import UserInterface from './components/UserInterface'
import UserModal from './components/UserModal'
import {AddButton, EditButton} from './components/UserButtons'

const Users: FC = () => {
  const {t} = useTranslation(['users'])
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [selectedUser, setSelectedUser] = useState<UserInterface | null>(null)
  const [showModal, setShowModal] = useState(false)
  const {users, addUser, updateUser, removeUser} = useUserContext()
  const {currentUser} = useAuth()

  const handleUserSave = (updatedUser: UserInterface) => {
    // Editar Utilizador
    if (updatedUser.id)
      toast.promise(updateUser(updatedUser), {
        pending: t('message.pending'),
        success: t('message.edit.success'),
        error: t('message.edit.error'),
      })
    // Novo Utilizador
    else
      toast.promise(addUser(updatedUser), {
        pending: t('message.pending'),
        success: t('message.insert.success'),
        error: t('message.insert.error'),
      })
  }

  const handleUserRemove = (removedUser: UserInterface) => {
    if (removedUser.id)
      toast.promise(removeUser(removedUser.id), {
        pending: t('message.pending'),
        success: t('message.delete.success'),
        error: t('message.delete.error'),
      })
  }

  const handleUserModal = (user: UserInterface | null) => {
    // Previne edição do próprio utilizador
    if (currentUser && user && Number(currentUser.id) === Number(user.id)) return

    setSelectedUser(user)
    setShowModal(true)
  }

  return (
    <>
      <div className='card card-custom'>
        <div className='card-header'>
          <h3 className='card-title'>{t('card.title')}</h3>
          <div className='card-toolbar'>
            <InputSearch searchTerm={searchTerm} setSearchTerm={setSearchTerm} className='w-250px'>
              {t('card.search')}
            </InputSearch>
            <AddButton setUser={() => handleUserModal(null)} />
          </div>
        </div>
        <div className='card-body'>
          <table className='table table-row-dashed align-middle gy-7 gs-7'>
            <thead>
              <tr className='fw-bolder fs-6 text-gray-800'>
                <th>{t('table.name')}</th>
                <th>{t('table.email')}</th>
                <th>{t('table.phone')}</th>
                <th>{t('table.updated_by')}</th>
                <th>{t('table.updated_at')}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user: UserInterface, index: number) =>
                user.name.toLowerCase().includes(searchTerm.toLowerCase()) || searchTerm === '' ? (
                  <tr key={index}>
                    <td>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-circle symbol-50px overflow-hidden me-3'>
                          <div className='symbol-label'>
                            <img
                              src={toAbsoluteUrl(user?.pic ? user.pic : '/media/avatars/blank.png')}
                              alt={user.name}
                              className='w-100'
                            />
                          </div>
                        </div>
                        <div className='d-flex flex-column align-items-start'>
                          <span className='text-gray-800 text-hover-primary mb-1'>{user.name}</span>
                          {user.admin ? (
                            <div className='badge badge-light-success fw-bolder'>Admin</div>
                          ) : null}
                        </div>
                      </div>
                    </td>
                    <td>{user.email}</td>
                    <td>{user.phone}</td>
                    <td>{user.updated_by}</td>
                    <td>{user.updated_at ? formatDate(user.updated_at) : ''}</td>
                    <td>
                      {currentUser && Number(currentUser.id) !== Number(user.id) && (
                        <EditButton setUser={() => handleUserModal(user)} />
                      )}
                    </td>
                  </tr>
                ) : null
              )}
            </tbody>
          </table>
        </div>
      </div>

      <UserModal
        show={showModal}
        setShow={setShowModal}
        user={selectedUser}
        onSave={handleUserSave}
        onRemove={selectedUser ? handleUserRemove : null}
      />
    </>
  )
}
export default Users
