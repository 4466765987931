/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
  const {t, i18n} = useTranslation(['auth'])

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) root.style.height = '100%'

    return () => {
      if (root) root.style.height = 'auto'
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
      {/* begin::Body */}
      <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
        {/* begin::Form */}
        <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
          {/* begin::Wrapper */}
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/default.svg')} className='h-50px' />
          <div className='w-lg-500px p-10'>
            <Outlet />
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Form */}

        {/* begin::Footer */}
        <div className='d-flex flex-center flex-wrap px-5'>
          {/* begin::Links */}
          <div className='d-flex fw-semibold text-primary fs-base'>
            {/*<a href='#' className='px-5' target='_blank'>
              {t('link.terms')}
            </a>

            <a href='#' className='px-5' target='_blank'>
              {t('link.plans')}
            </a>*/}

            <a
              href={`https://systems4you.pt/${i18n.language === 'pt' ? 'contactos' : 'contacts'}`}
              className='px-5'
              target='_blank'
              rel='noreferrer'
            >
              {t('link.contact')}
            </a>
          </div>
          {/* end::Links */}
        </div>
        {/* end::Footer */}
      </div>
      {/* end::Body */}

      {/* begin::Aside */}
      <div
        className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
        style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
      >
        {/* begin::Content */}
        <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
          {/* begin::Logo */}
          <a href='https://systems4you.pt' target='_blank' className='mb-12' rel='noreferrer'>
            <img alt='Logo' src={toAbsoluteUrl('/media/logos/custom-1.png')} className='h-75px' />
          </a>
          {/* end::Logo */}

          {/* begin::Image */}
          <img
            className='mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
            src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            alt=''
          />
          {/* end::Image */}

          {/* begin::Title */}
          <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
            Internet das Coisas
            <br />
            Industria 4.0
          </h1>
          {/* end::Title */}

          {/* begin::Text */}
          <div className='text-white fs-base text-center'>
            Modernize a sua produção
            <br />
            com os nossos sistemas de supervisão e aquisição de dados.
            <br />
            Entre no futuro agora!
          </div>
          {/* end::Text */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::Aside */}
    </div>
  )
}

export {AuthLayout}
