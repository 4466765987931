import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {UserContextProvider} from '../modules/users'
import {DeviceContextProvider} from '../modules/devices'
import Home from '../pages/home'
import History from '../pages/history'
import Stats from '../pages/stats'
import Users from '../pages/users'
import Account from '../pages/account'
import Settings from '../pages/settings'

const PrivateRoutes = () => {
  const Device = lazy(() => import('../pages/device'))

  return (
    <UserContextProvider>
      <DeviceContextProvider>
        <Routes>
          <Route element={<MasterLayout />}>
            {/* Redirect to Home after success login */}
            <Route path='auth/*' element={<Navigate to='/home' />} />
            {/* Pages */}
            <Route path='home' element={<Home />} />

            <Route
              path='device/*'
              element={
                <SuspensedView>
                  <Device />
                </SuspensedView>
              }
            />

            <Route path='history' element={<History />} />

            <Route path='stats' element={<Stats />} />

            <Route path='users' element={<Users />} />

            <Route path='account' element={<Account />} />

            <Route path='settings' element={<Settings />} />

            {/* Page Not Found */}
            <Route path='*' element={<Navigate to='/error/404' />} />
          </Route>
        </Routes>
      </DeviceContextProvider>
    </UserContextProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
