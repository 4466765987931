import {createContext, useContext, useEffect, useState} from 'react'
import axios from 'axios'
import {DataInterface} from './components/DataInterface'

interface DatasetContextType {
  dataset: DataInterface[]
}

const DatasetContext = createContext<DatasetContextType | undefined>(undefined)

export const useDatasetContext = () => {
  const context = useContext(DatasetContext)
  if (!context) {
    throw new Error('useDatasetContext must be used within a DatasetProvider')
  }
  return context
}

export function DatasetContextProvider({children}) {
  const [dataset, setDataset] = useState<DataInterface[]>([])

  const fetchDataset = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/data`)
      .then((response) => {
        setDataset(
          response.data.map((item) => {
            item.timestamp = new Date(item.inserted_at)
            return item
          })
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  useEffect(() => {
    fetchDataset()
  }, [])

  return <DatasetContext.Provider value={{dataset}}>{children}</DatasetContext.Provider>
}
