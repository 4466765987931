import {useState, useEffect} from 'react'
import {Modal, Button} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import UserInterface from './UserInterface'
import SwitchButton from '../../../GenericComponents/SwitchButton'
import * as Yup from 'yup' // Import Yup for validation
import {useFormik} from 'formik' // Import useFormik hook from formik library

interface ModalProps {
  show: boolean
  setShow: (state: boolean) => void
  user: UserInterface | null
  onSave: (updatedUser: UserInterface) => void
  onRemove: ((removedUser: UserInterface) => void) | null
}

const emptyUser: UserInterface = {
  id: null,
  name: '',
  email: '',
  phone: undefined,
  password: undefined,
  updated_by: undefined,
  updated_at: undefined,
  admin: false,
}

const UserModal = ({show, setShow, user, onSave, onRemove}: ModalProps) => {
  const {t} = useTranslation(['users'], {keyPrefix: 'modal'})
  const [manualPassword, setManualPassword] = useState(false)

  // Define the validation schema for the UserInterface
  const userSchema = Yup.object().shape({
    name: Yup.string().required(t('name.required')),
    email: Yup.string().email(t('email.invalid')).required(t('email.required')),
    phone: Yup.string().nullable(),
    password: manualPassword
      ? Yup.string().required(t('password.required'))
      : Yup.string().nullable(),
  })

  // Use useFormik hook to handle form state and validation
  const formik = useFormik({
    initialValues: user || emptyUser,
    validationSchema: userSchema,
    enableReinitialize: true,
    validateOnChange: true,
    onSubmit: (values) => {
      onSave(values)
      resetForm()
    },
  })

  const handleRemove = () => {
    if (onRemove && user) onRemove(user)
    resetForm()
  }

  const resetForm = () => {
    setShow(false)
    setManualPassword(false)
    formik.resetForm()
  }

  useEffect(() => {
    formik.validateField('password')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manualPassword])

  return (
    <Modal show={show} onHide={resetForm}>
      <Modal.Header closeButton>
        <Modal.Title>{t('title.' + (user ? 'edit' : 'add')) + ' ' + t('title.user')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className='mb-10'>
            <label className='required form-label'>{t('name.label')}</label>
            <input
              type='text'
              className='form-control form-control-solid'
              placeholder={t('name.placeholder')}
              {...formik.getFieldProps('name')}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className='text-danger'>{formik.errors.name}</div>
            ) : null}
          </div>
          <div className='mb-10'>
            <label className='required form-label'>{t('email.label')}</label>
            <input
              type='email'
              className='form-control form-control-solid'
              placeholder={t('email.placeholder')}
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className='text-danger'>{formik.errors.email}</div>
            ) : null}
          </div>
          <div className='mb-10'>
            <label className='form-label'>{t('phone.label')}</label>
            <input
              type='phone'
              className='form-control form-control-solid'
              placeholder={t('phone.placeholder')}
              {...formik.getFieldProps('phone')}
            />
          </div>

          <SwitchButton value={manualPassword} onChange={setManualPassword}>
            {t('password.label')}
          </SwitchButton>

          {manualPassword ? (
            <div className='mb-10 mt-5'>
              <input
                type='password'
                className='form-control form-control-solid'
                placeholder={t('password.placeholder')}
                {...formik.getFieldProps('password')}
              />
              {formik.touched.password && formik.errors.password ? (
                <div className='text-danger'>{formik.errors.password}</div>
              ) : null}
            </div>
          ) : null}
        </form>
      </Modal.Body>

      <Modal.Footer>
        {onRemove && user ? (
          <Button variant='danger' disabled={formik.isSubmitting} onClick={handleRemove}>
            {t('button.delete')}
          </Button>
        ) : (
          <Button variant='secondary' disabled={formik.isSubmitting} onClick={resetForm}>
            {t('button.cancel')}
          </Button>
        )}
        <Button
          type='submit'
          variant='primary'
          disabled={formik.isSubmitting || !formik.isValid}
          onClick={() => formik.handleSubmit()}
        >
          {t('button.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default UserModal
