import {FC, useState, useEffect} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import axios from 'axios'
import {CompanyInterface} from './components/SettingsInterface'
import CompanyDetails from './components/CompanyDetails'

const Settings: FC = () => {
  const {t} = useTranslation(['settings'])
  const [company, setCompany] = useState<CompanyInterface | null>(null)

  const fetchCompany = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/company`).then((response) => {
      setCompany(response.data)
    })
  }

  const handleCompanySave = (updatedCompany: CompanyInterface) => {
    toast.promise(
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/company/`, updatedCompany)
        .then((response) => {
          console.log(response)

          setCompany(updatedCompany)
        })
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      {
        pending: t('pending'),
        success: t('message.general.success'),
        error: t('message.general.error'),
      }
    )
  }

  useEffect(() => {
    fetchCompany()
  }, [])

  return company && <CompanyDetails company={company} onSave={handleCompanySave} />
}

export default Settings
