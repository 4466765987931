import {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {toast} from 'react-toastify'
import axios from 'axios'
import {UserModel, useAuth} from '../../modules/auth'
import {PasswordInterface} from './components/SignInInterface'
import {ProfileDetails} from './components/ProfileDetails'
import {SignInMethod} from './components/SignInMethod'
import {ProfilePicture} from './components/ProfilePicture'

const Account: FC = () => {
  const {t} = useTranslation(['account'], {keyPrefix: 'message'})
  const {currentUser, setCurrentUser} = useAuth()

  const getPicture = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/account/picture`)
      .then((response) => {
        console.log(response)
        setCurrentUser((prevUser) =>
          prevUser ? Object.assign(prevUser, {pic: response.data.pic}) : prevUser
        )
      })
      .catch((error) => {
        const message = error.response.data.error
        console.error(message)
      })
  }

  const handlePictureSave = (updatedPicture: File) => {
    const formData = new FormData()
    formData.append('file', updatedPicture)

    toast.promise(
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/account/picture`, formData)
        .then((response) => {
          console.log(response)

          getPicture()
        })
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      {
        pending: t('pending'),
        success: t('picture.success'),
        error: t('picture.error'),
      }
    )
  }

  const handleProfileSave = (updatedProfile: UserModel) => {
    toast.promise(
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/account/basic`, updatedProfile)
        .then((response) => {
          console.log(response)

          setCurrentUser((prevUser) =>
            prevUser ? Object.assign(prevUser, updatedProfile) : prevUser
          )
        })
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      {
        pending: t('pending'),
        success: t('details.success'),
        error: t('details.error'),
      }
    )
  }

  const handlePasswordUpdate = (password: PasswordInterface) => {
    toast.promise(
      axios
        .post(`${process.env.REACT_APP_API_URL}/api/account/password`, password)
        .then((response) => {
          console.log(response)
        })
        .catch((error) => {
          console.error(error.response.data.error)
          return Promise.reject()
        }),
      {
        pending: t('pending'),
        success: t('password.success'),
        error: t('password.error'),
      }
    )
  }

  return (
    <>
      {currentUser && (
        <>
          <ProfilePicture url={currentUser.pic} onSave={handlePictureSave} />
          <ProfileDetails user={currentUser} onSave={handleProfileSave} />
        </>
      )}
      <SignInMethod onPasswordUpdate={handlePasswordUpdate} />
    </>
  )
}

export default Account
