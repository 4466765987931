export function getBackground(status: 0 | 1 | 2): string {
  switch (status) {
    case 0:
      return 'bg-success'
    case 1:
      return 'bg-warning'
    case 2:
      return 'bg-danger'
  }
}

export function formatTime(time: number): string {
  const days = Math.floor(time / (3600 * 24))
  const hours = Math.floor((time % (3600 * 24)) / 3600)
  const minutes = Math.floor((time % 3600) / 60)
  const seconds = time % 60

  const formattedDays = days > 0 ? `${days}d ` : ''
  const formattedHours = hours > 0 ? `${hours}h ` : ''
  const formattedMinutes = minutes > 0 ? `${minutes}m ` : ''
  const formattedSeconds = seconds > 0 ? `${seconds}s` : ''

  return formattedDays + formattedHours + formattedMinutes + formattedSeconds
}

export function formatDate(date: Date | number): string {
  const now = new Date() // Get the current date and time
  const newDate = new Date(date)
  const isSameDay = now.getDate() === newDate.getDate() && now.getMonth() === newDate.getMonth()

  let formattedDate = ''
  if (!isSameDay) {
    const day = newDate.getDate().toString().padStart(2, '0')
    const month = (newDate.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based, so add 1
    formattedDate = `${day}-${month} `
  }

  const hours = newDate.getHours()
  const minutes = newDate.getMinutes()
  const formattedHours = hours.toString().padStart(2, '0')
  const formattedMinutes = minutes.toString().padStart(2, '0')

  return `${formattedDate}${formattedHours}:${formattedMinutes}`
}
