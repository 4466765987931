import {FC} from 'react'
import {DeviceContextProvider} from '../../modules/devices'
import {DatasetContextProvider} from '../../modules/dataset'
import Devices from './components/Devices'
import Messages from './components/Messages'
import LastMessages from './components/LastMessages'
import Chart from './components/Chart'

const Home: FC = () => {
  return (
    <div className='row'>
      <div className='col-md-6 col-lg-12 col-xl-6'>
        <DeviceContextProvider>
          <Devices />
        </DeviceContextProvider>
      </div>
      <DatasetContextProvider>
        <div className='col-md-6 col-lg-12 col-xl-6'>
          <Messages />
        </div>
        <div className='col-md-6 col-lg-12 col-xl-6'>
          <LastMessages />
        </div>
        <div className='col-md-6 col-lg-12 col-xl-6'>
          <Chart />
        </div>
      </DatasetContextProvider>
    </div>
  )
}

export default Home
