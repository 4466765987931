export interface PasswordInterface {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export const updatePassword: PasswordInterface = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}
