import {createContext, useContext, useEffect, useState} from 'react'
import axios from 'axios'
import {useAuth} from '../auth'
import UserInterface from '../../pages/users/components/UserInterface'

interface UserContextType {
  users: UserInterface[]
  addUser: (insertedUser: UserInterface) => Promise<void>
  updateUser: (updatedUser: UserInterface) => Promise<void>
  removeUser: (id: number) => Promise<void>
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export const useUserContext = () => {
  const context = useContext(UserContext)
  if (!context) {
    throw new Error('useUserContext must be used within a UserProvider')
  }
  return context
}

export function UserContextProvider({children}) {
  const {auth, currentUser} = useAuth()
  const [users, setUsers] = useState<UserInterface[]>([])

  const fetchUsers = () => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/api/user/list`)
      .then((response) => {
        setUsers(response.data ?? [])
      })
      .catch((error) => {
        console.error(error.response.data.error)
      })
  }

  const removeUser = async (id: number) => {
    return axios
      .delete(`${process.env.REACT_APP_API_URL}/api/user/${id}`)
      .then(() => {
        setUsers((prevUsers) => prevUsers.filter((m) => m.id !== id))
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const updateUser = async (updatedUser: UserInterface) => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/user/${updatedUser.id}`, updatedUser)
      .then((response) => {
        console.log(response)

        setUsers((prevUsers) =>
          prevUsers.map((m) => {
            if (m.id === updatedUser.id) {
              updatedUser.updated_at = new Date()
              updatedUser.updated_by = currentUser ? currentUser.name : ''
              delete updatedUser.password
              return updatedUser
            } else return m
          })
        )
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  const addUser = async (insertedUser: UserInterface) => {
    return axios
      .put(`${process.env.REACT_APP_API_URL}/api/user`, insertedUser)
      .then((response) => {
        console.log(response)

        const {id_user} = response.data
        setUsers((prevUsers) => {
          insertedUser.id = id_user
          insertedUser.updated_at = new Date()
          insertedUser.updated_by = currentUser ? currentUser.name : ''
          delete insertedUser.password
          return [...prevUsers, insertedUser]
        })
      })
      .catch((error) => {
        console.error(error.response.data.error)
        return Promise.reject()
      })
  }

  useEffect(() => {
    if (auth) {
      fetchUsers()
    }
  }, [auth])

  return (
    <UserContext.Provider value={{users, addUser, updateUser, removeUser}}>
      {children}
    </UserContext.Provider>
  )
}
